import $ from "jquery";
import { render_array, } from "../shared/render-helpers.js";

window.initializeServices = (selector) => {
  $.fn.dataTable.ext.errMode = "none";
  $(selector).DataTable({
    responsive: true,
    columns: [
      { title: 'Name', data: 'name', 'class': 'col-1',
        render: (d, t, r) =>
        {
          if (t === "display")
            return `<a href="/user/submit/${d}" title='${r.description}'>${d}</a>` +
              ` <a href='/services/${r.service ? r.service : d}' title='learn more'><span class='fa fa-question'></a>`;
          return d;
        }
      },
      { title: 'Date', data: 'content.date', 'class': 'col-1'},
      { title: 'Category', data: 'content.category', 'class': 'col-1' },
      { title: 'Outputs', data: 'content.outputs', render: render_array() },
      { title: 'Keywods', data: 'content.keywords', visible: false},
      { title: 'Protocol', data: 'content.protocol', render: render_array() },
      { data: 'content.service', render: (d, t, r) =>
        { if (t == "display") return `<a href='/services/${d ? d : r.name}' title='learn more'><span class='fa fa-question'/></a>`;}
      },
      { visible: false }
    ],
    ajax: {
      url: "/api/v1.0/pipelines",
      dataSrc: "",
    },
    paging: false,
    layout: {
      topStart: 'search',
      topEnd: null,
      bottomStart: 'info',
      bottomEnd: null,
    }
  });
};
